<template v-if="renderNow">
  <v-card class="mk-card-radius pa-4">
    <!--    <v-card-title class="title mk-highlight-text mb-5"-->
    <!--      >{{!$language ? 'Project Details' : 'প্রজেক্টের বিস্তারিত'}}</v-card-title-->
    <!--    >-->
    <page-title
      previous-location
      :page-title="!$language ? 'Project Details' : 'প্রজেক্টের বিস্তারিত'"
    ></page-title>
    <m-container class="px-6 py-4">
      <v-expansion-panels v-model="currentStep">
        <v-expansion-panel v-for="(step, i) in project.project_steps" :key="i">
          <!--        need to add a Step counter.-->
          <v-expansion-panel-header class="expansion-header">
            <v-col class="pa-0"
              >{{ !$language ? "Step" : "স্টেপ" }} {{ i + 1 }}
              {{ !$language ? "Details" : "এর বিস্তারিত" }}</v-col
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <step-details-form-show
              :project-id="project_id"
              :batch-id="batch_id"
              :step-details="step"
              :step-index="i"
              :readonly="true"
              :step-ok.sync="stepOkArray[i]"
            ></step-details-form-show>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </m-container>
  </v-card>
</template>

<script>
import StepDetailsFormShow from "#ef/project/components/StepDetailsFormShow";
import projectService from "#ef/project/services/ProjectService";

export default {
  name: "ProjectDetailsPage",
  components: { StepDetailsFormShow },
  props: {
    batch_id: String,
    project_id: String,
  },
  data() {
    return {
      renderNow: false,
      currentStep: 0,
      stepOkArray: [],
      project: undefined,
    };
  },
  created() {
    this.fetchProjectDetails();
  },
  methods: {
    async fetchProjectDetails() {
      try {
        this.project = await projectService.getProject(
          this.project_id,
          this.$attrs.course_id,
          this.batch_id,
        );
        this.renderNow = true;
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          e.message,
        ]);
      }
    },
  },
};
</script>

<style scoped></style>
